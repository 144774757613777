import { Route, Routes } from 'react-router-dom'

import HomePage from '../pages/home/HomePage';
import AboutPage from '../pages/about/AboutPage';
import ServicesPage from '../pages/services/ServicesPage';
import ServicePage from '../pages/services/ServicePage';
import ContactPage from '../pages/contact/ContactPage';

const Routers = () => {
  return (
    <Routes>
            <Route path="/" element={<HomePage /> } />
            <Route path="/about" element={<AboutPage /> } />
            <Route path="/services" element={<ServicesPage /> } />
            <Route path="/services/:id" element={<ServicePage />} />
            <Route path="/contact" element={<ContactPage /> } />
    </Routes>
  )
}

export default Routers