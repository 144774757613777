import {BrowserRouter} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ParallaxProvider } from 'react-scroll-parallax';

import Routers from './components/common/Routers';
import NavBar from './components/common/nav/NavBar.';
import Footer from './components/common/footer/Footer';
import ToasterHook from './hooks/ToasterHook';


function App() { 
   
  return (
   <BrowserRouter>
      <ToasterHook />
      <NavBar />
      <HelmetProvider>
      <ParallaxProvider>
          <Routers />
      </ParallaxProvider>
      </HelmetProvider>
      <Footer />
   </BrowserRouter>
  );
}

export default App;
