import React from 'react';
import Icon from './Icon';

const List = ({icon, details}) => {
  return (
    <p className='list'>
        <Icon iconName={icon} propsIcon={{ size: 30, fill: 'orange'}}/>{details}
    </p>
  )
}

export default List
