export const HomePageStore = (set) => ({
    intro: {
        heading: 'Providing Quality Electrical Service in the Chicagoland',
        paragraphs: 'We are, family-owned business offering all aspects of electrical contracting for residential and commercial properties. If you need an expert electrician and you want professionals who know the ins and outs of wiring, circuits, codes, and the latest in lighting technology, you need Go Electrical US. We`re proud to provide our services around Chicago so you can enjoy safe, reliable electricity in your homes and businesses. Need an Electrician near me look no further and call us today.'
    },

    branding: {
        heading: 'why customers love go electrical US? because we provide unique services.',
        paragraphs: 'Our aim is to provide professional and unique services to customers, and we have provided high quality services to our customers thats why they love travel friends.'
    },

    cards: [
        {icon: 'AiOutlineHome', heading: 'Residential', paragraphs: 'Your family`s safety is important and an electrical issue can be hazardous to them an your home. We offer electrical repairs, generator installation, indoor and outdoor lighting, panel upgradets and more. Our electricians are professional, courteous and provide quality workmanship to every residential customer.'},
        {icon: 'AiFillThunderbolt', heading: 'Comercial', paragraphs: 'Avoid a delay in business operations because of an electrical issue. Our team handles any type of wiring, data, communication, electrical wiring; electrical repaires, lighting and more for you new or exisiting commercial project.'},
        {icon: 'AiFillTool', heading: '24/7 Emergency', paragraphs: 'Fast, professional, fully trained and committed to customer service, Our team of technicians is ready to handle any form of electrical emergency. No matter the time of day, if you need help with an electrical problem, call us and we will get there right away.'},
    ],
    
    gallery: [
        //'https://i.ibb.co/CHLBZnp/gal2323.jpg', 'https://i.ibb.co/4pBbhfY/gal39834.jpg', 'https://i.ibb.co/xSnHP7g/gal43884.jpg', 'https://i.ibb.co/QN6Bnrb/gal4958.jpg', 'panel.jpg', 'https://i.ibb.co/S6FVFNt/gal74744.jpg'
        'gal2323.jpg', 'gal39834.jpg', 'gal43884.jpg', 'gal43884.jpg', 'panel.jpg', 'gal4958.jpg'
    ],
    
    reviews: [
        {name: 'Bogdan Rusu', review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus odio eget lorem laoreet consectetur. Morbi sit amet felis dui. Cras a sem ipsum. Nullam interdum et risus nec sagittis. Donec sed dolor quam. Suspendisse molestie fringilla tellus in condimentum. Duis varius tellus ac urna posuere, at porttitor augue sollicitudin.'},
        {name: 'Elena Rusu', review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus odio eget lorem laoreet consectetur. Morbi sit amet felis dui. Cras a sem ipsum. Nullam interdum et risus nec sagittis. Donec sed dolor quam. Suspendisse molestie fringilla tellus in condimentum. Duis varius tellus ac urna posuere, at porttitor augue sollicitudin.'},
        {name: 'Katerina Rusu', review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer rhoncus odio eget lorem laoreet consectetur. Morbi sit amet felis dui. Cras a sem ipsum. Nullam interdum et risus nec sagittis. Donec sed dolor quam. Suspendisse molestie fringilla tellus in condimentum. Duis varius tellus ac urna posuere, at porttitor augue sollicitudin.'},
    ],
})