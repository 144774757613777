export const AboutPageStore = (set) => ({
    AboutPage: { image: '/assets/electrician.jpg',
                 intro: { heading: 'Our Story',
                          subheading: 'Are you stumped by a home wiring project or problem? Fill out the form with the details of your situation and we can come to your aid.',
                        },
                 details: {
                            heading: 'About Go Electrical US',
                            description: [ 
                                            'We are a team of dedicated professionals who are passionate about providing top-quality electrical services to our clients. Our company has been in business for several years, and we have built a reputation for delivering exceptional workmanship and outstanding customer service.',
                                            'Our team of skilled electricians is fully licensed, insured, and trained to handle any electrical project, big or small, with the highest level of professionalism and attention to detail. We use only the best quality materials and the latest industry-standard tools and equipment to ensure that our work is of the highest standard and complies with all relevant safety codes and regulations.',
                                            'At our company, we understand that every electrical project is unique, and we take the time to listen to our clients needs and requirements to ensure that we deliver tailored solutions that meet their specific needs. Whether you need electrical installation, repair, maintenance, or upgrade services, we have the expertise and experience to get the job done right the first time.'
                                        ]
                            },                 
                 colapsedList: [
                    {
                        heading: 'Our Values',
                        description: [ 'Lorem ipsum dolor sit amet, consectetur adipisicing elited do eiusmod tempor incididunt ut labore et dolore a le ad veniam, quis nostrud citation ullamco laboris. Lite amet, consectetur adipisicing elit.' ]
                    },
                    { 
                        heading: 'Our Mission',
                        description: [ 'Our mission is to empower our clients with top-quality electrical solutions that meet their unique needs and requirements. We are passionate about providing exceptional workmanship and outstanding customer service, and we believe that our clients satisfaction is the ultimate measure of our success.']
                    },
                    {
                        heading: 'Our Goals',
                        description: [ 'Lorem ipsum dolor sit amet, consectetur adipisicing elited do eiusmod tempor incididunt ut labore et dolore a le ad veniam, quis nostrud citation ullamco laboris. Lite amet, consectetur adipisicing elit.']
                    }
                 ],

                 details_second: {
                        heading: 'Trusted Electrician in Chicago',
                        paragraph: 'We understand that hiring an electrician can be a daunting task, which is why we prioritize open and transparent communication with our clients. Our team takes the time to understand your unique needs and concerns, and we work closely with you to develop tailored solutions that meet your specific requirements.It`s with that in mind that we offer:',
                        details: [
                            'In-Home Consultations', 'Honest, Up-Front Pricing', 'Same-Day Service', '24/7 Availability for Emergencies'
                        ]
                 },

                 cards: [
                    {icon: 'CiCalendarDate', heading: 'Always Available', paragraphs: ' Our electricians are available 24/7 to provide fast  emergency repair  for all your electrical needs.'},
                    {icon: 'CiUser', heading: 'Licenced Electricians', paragraphs: 'Don`t risk it - trust a licensed electrician for all your electrical needs.'},
                    {icon: 'CiGrid41', heading: 'Best Quality', paragraphs: 'When it comes to quality electrical work, we don`t settle for anything less than the best.'},
                    {icon: 'CiShoppingCart', heading: 'Best Offers', paragraphs: 'Don`t miss out on our unbeatable offer - electrify your space for less!'},
                ],

                parallax: {
                    image: '/assets/electrician2.jpg',
                    heading: 'quality comes first',
                    paragraph: 'At our company, quality is more than just a promise - it`s our unwavering commitment to excellence.',
                          },
                },


         })