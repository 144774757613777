import React from 'react';

import Card from '../../../../common/Card'
import Container from '../../../../common/Container';


const Services = ({data}) => {
   
  return (
    <section className='services' data-aos="fade-right">
      <Container>
          <div className=" services__container flex mt-8">
              { data.length > 0 ? data.map((card, index) =>
                <div className="col-4">
                  <Card key={index} icon={card.icon} heading={card.heading} paragraphs={card.paragraphs}/>
                  </div>
              ) : ''}
              
          </div>
    </Container>
    </section>
  )
}

export default Services