import React from 'react';
import Container from '../../../../common/Container';
import SkillsBar from './SkillsBar';

const Branding = ({data}) => {
    const {heading, paragraphs} = data;
  return (
    <section className='branding' data-aos="fade-right">
        <Container>
            <div className="flex">
                <div className="col-6">
                    <h1 className='heading-secondary'>{heading}</h1>
                    <p className="paragraph">{paragraphs}</p>
                </div>
                <div className="col-6"><SkillsBar /></div>
            </div>
        </Container>
    </section>
  )
}

export default Branding
