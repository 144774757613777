import React, {useEffect, useRef, useState} from 'react';
import {NavLink, useLocation } from 'react-router-dom';

import { useBoundStore } from '../../../store/index';
import Container from '../Container';
import SubMenuLink from '../SubMenuLink';


const NavBar = () => {

   
   const {links} = useBoundStore((state) => state);

   const [toggle, setToogle] = useState(false);
   const [active, setActive] = useState('');

   const openNav = useRef();
   const element = useRef();

   let newValue = 0;
   let oldValue = 0;
   const location = useLocation();

   useEffect(()=>{
      setToogle()
   }, [location])

   useEffect(()=>{
      window.addEventListener('scroll', handleScroll);

      return () => window.removeEventListener('scroll', handleScroll)
   }, []);

   useEffect(() =>{
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize)
   }, []);

   useEffect(() =>{
      openNav.current.addEventListener('click',  handleSubMenu);        
      return () => openNav.current.removeEventListener('click', handleSubMenu);        
  }, [])

   const handleResize = () =>{
      if(window.innerWidth < 761 && element.current.classList.contains('fixedNav')){
            element.current.classList.remove('floatNav')
            element.current.classList.remove('fixedNav')
      }
   }
 
   const handleScroll = () =>{
      newValue = window.pageYOffset;
      
      if (oldValue < newValue && window.scrollY > 300 && window.innerWidth > 761 ) {      
         element.current.classList.add('fixedNav'); 
   
       }
       if (oldValue < newValue && window.scrollY > 400 && window.innerWidth > 761 ) {      
         element.current.classList.add('floatNav'); 
   
       } else if (oldValue > newValue &&  window.scrollY < 1 && window.innerWidth > 761) {
         element.current.classList.remove('floatNav');
         element.current.classList.remove('fixedNav');
       }
       oldValue = newValue    
   }

   const handleSubMenu = (event) =>{
      if(event.target.hasAttribute('data-toggle') && window.innerWidth <= 991){
          event.preventDefault();
          const menuItemHasChildren = event.target.parentElement;
          
          if(menuItemHasChildren.classList.contains('active')){
              colapseSubMenu()
          }
          else{
              menuItemHasChildren.classList.add('active')
              const subMenu = menuItemHasChildren.querySelector(".sub-menu");
              subMenu.style.maxHeight = subMenu.scrollHeight + "px";
          }
      }
  };

  const colapseSubMenu = () =>{
      openNav.current.querySelector(".menu-item-has-children.active .sub-menu")
      .removeAttribute("style");
      openNav.current.querySelector(".menu-item-has-children.active")
      .classList.remove("active");
};

   const handleToggle = () =>{
      setToogle(!toggle)
   }

  return (
   <section className="navbar" ref={element}>
      <Container>
      <div className="navbar__main">
                  <div className="logo">
                     <NavLink to="">LOGO</NavLink>
                  </div>
                  <div onClick={handleToggle} className="open-nav-menu">
                     <span></span>
                  </div>
                  <div className={toggle ? 'menu-overlay active' : 'menu-overlay'}>
                  </div>
                  {/* NAVIGATION */}
                     <nav  ref={openNav} className={toggle ? 'nav-menu open' : 'nav-menu'}>
                           <div onClick={handleToggle} className="close-nav-menu">
                              <span>Close</span>
                           </div>
                           <ul className="menu">
                              <li className="menu-item">
                                 <NavLink to="/">Home</NavLink>
                              </li>
                              <li className="menu-item">
                                 <NavLink to="/about">About</NavLink>
                              </li>
                              <li className="menu-item menu-item-has-children">
                                 <NavLink to="#" data-toggle="sub-menu">Services<i className="plus"></i></NavLink>
                                 <ul className="sub-menu">
                                    {links.length > 0 &&
                                       links.map((link, index) => 
                                       <li key={index} className="menu-item"> <SubMenuLink data={link} /></li>
                                       )}                                       
                                 </ul>
                              </li>
                              <li className="menu-item">
                                 <NavLink to="/contact">Contact</NavLink>
                              </li>
                           </ul>
                     </nav>
                  {/* NAVIGATION */}
               </div>
      </Container>
</section>
  )
}

export default NavBar