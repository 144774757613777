export const ServicesStore = (set) => ({
    
    servicesPage: {
        electrical_panel: {
            image: '/assets/electrician2.jpg',
            heading: 'Electrical Panels - Instaling, upgrades',
            description: [
                    'Electrical panels are an important part of your home electrical system as they help to distribute electricity to different areas of your home. Over time, electrical panels may become damaged or outdated and may need to be inspected, repaired, or upgraded.',
                    'Indoor lights that frequently flicker or dim, circuit breakers that often trip, or an electrical panel that feels warm are signals that your electrical panel needs attention. Let us give you the peace of mind of knowing you are safe.',
                    'An inspection of your electrical panel can help to identify any safety hazards or issues with the panel that may need to be addressed. For example, if the panel is damaged or has corroded components, it can be a safety hazard that could cause electrical shocks or fires.',
                    'If your home has an older electrical panel, it may not be able to handle the increased electrical demands of modern appliances and electronics. This can cause the panel to overheat and potentially start a fire. Upgrading your electrical panel can help to ensure that it can safely handle the electrical needs of your home.',
                    'In summary, regular inspection, repair, or upgrading of your electrical panel can help to ensure that your home electrical system is safe and up-to-date. This can help to prevent electrical hazards, improve electrical efficiency, and increase the overall safety of your home.',
                    
                    ],
            list: ['Electrical Panel Installation', 'Electrical Panel Replacement', 'Electrical Panel Relocation', 'Electrical Panel Repair']
        },

        electrical_wiring:{
            image: '/assets/electrician2.jpg',
            heading: 'Electrical Wiring  - Installations, repairs and  upgrades',
            description: ['When you need services for your Chicago home`s electrical panel, be sure to choose professionals with the skills and experience to get the job done safely and in a way that meets all municipal codes. Four Seasons Electrical demands the best for our customers, and we only hire qualified, licensed electricians with proven expertise.',],
            list: ['Electrical Panel Installation', 'Electrical Panel Replacement', 'Electrical Panel Relocation', 'Electrical Panel Repair']
        },

        electric_vehicle_chargers: {
            image: '/assets/electrician2.jpg',
            heading: 'Electric Vehicle Chargers - Installations, repairs and  upgrades',
            description: [
                'One of the primary reasons for installing an EV charger is convenience. Having a charging station at home eliminates the need to drive to a public charging station or worry about the availability of charging spots. It also allows for charging at any time, making it possible to top up the battery overnight while the car is parked.',
                'Another reason for installing an EV charger is cost savings. The cost of electricity is significantly lower than the cost of gasoline, making EVs a more economical option in the long run. Additionally, many utility companies offer special rates for EV charging, further reducing the cost of charging.', 
                'Installing an EV charger can also increase the value of a home. With the rise in popularity of EVs, homes with charging stations are becoming more desirable to buyers. This can make a home with an EV charger more attractive to potential buyers and increase the overall value of the property.',
                'We provide reliable and professional Level 1 and Level 2 electric car charger installation services for both residential and commercial properties. We make it easy for you to get your home EV charger installed with our comprehensive understanding of all building codes within our service area.',
                'All of our electric car charging station installations are permitted and inspected by their respective jurisdictions. The process may include installing a dedicated circuit for EV charging, installing an EV charging station, upgrading an existing electrical panel, or adding a second electrical panel.'
            ],
            list: ['At-Home Consultations', 'Installation by Licensed Electrician', 'Upgrades and Expansion of EV Charging Station', 'EV Charger Repair']
        },

        indoor_outdoor_lighting:{
            image: '/assets/electrician2.jpg',
            heading: 'Indoor / Outdoor Lighting  - Installations, repairs and  upgrades',
            description: ['When you need services for your Chicago home`s electrical panel, be sure to choose professionals with the skills and experience to get the job done safely and in a way that meets all municipal codes. Four Seasons Electrical demands the best for our customers, and we only hire qualified, licensed electricians with proven expertise.',],
            list: ['Electrical Panel Installation', 'Electrical Panel Replacement', 'Electrical Panel Relocation', 'Electrical Panel Repair']
        },

        smoke_alarms:{
            image: '/assets/electrician2.jpg',
            heading: 'Smoke Alarms - Installations, repairs and  upgrades',
            description: ['When you need services for your Chicago home`s electrical panel, be sure to choose professionals with the skills and experience to get the job done safely and in a way that meets all municipal codes. Four Seasons Electrical demands the best for our customers, and we only hire qualified, licensed electricians with proven expertise.',],
            list: ['Electrical Panel Installation', 'Electrical Panel Replacement', 'Electrical Panel Relocation', 'Electrical Panel Repair']
        },

        generators:{
            image: '/assets/electrician2.jpg',
            heading: 'Backup Generators — Automatic Standby Generators',
            description: ['When you need services for your Chicago home`s electrical panel, be sure to choose professionals with the skills and experience to get the job done safely and in a way that meets all municipal codes. Four Seasons Electrical demands the best for our customers, and we only hire qualified, licensed electricians with proven expertise.',],
            list: ['Electrical Panel Installation', 'Electrical Panel Replacement', 'Electrical Panel Relocation', 'Electrical Panel Repair']
        },
    }
   
})