import React, {useState} from 'react';
import {BiChevronRightCircle} from "react-icons/bi";


const ColapsedList = ({list}) => {

    const [current, setCurrent] = useState(0);

    const showDetails = (index) =>{
        setCurrent(index)
    }


  return  list.length > 0 ? 
        list.map((item, index) => (
            <div className="colapsed__list" key={index}> 
                    <h4 className={`colapsed__list__heading ${current===index && 'colapsed__list__heading--show'}`} onClick={()=>showDetails(index)}>
                        <BiChevronRightCircle className={`${ current === index ? 'icon__rotate' : 'icon__back'} `} size={25} />
                        {item.heading}
                    </h4>
                    <div  key={index} className={`colapsed__list__details ${current === index ? 'colapsed__list__details--show' : ''}`}>
                        {item.description.map((description, index)=>(
                        <p>{description}</p>
                        ))}
                    </div>
            </div>            
        )) : ''
}

export default ColapsedList
