import React, {useState, useEffect} from 'react';


const Carousel = ({slides}) => {
   
    const [currentSlide, setCurrentSlide] = useState(0);

  let slideInterval
	let intervalTime = 8000;
    const slideLength = slides.length;
    
    useEffect(()=>{setCurrentSlide(0)}, []);
    useEffect(()=>{
        auto();
        return () => clearInterval(slideInterval)
   },[currentSlide]);

    const auto = () =>{
		slideInterval = setInterval(nextSlide, intervalTime);
	}

    const nextSlide = () =>{
		setCurrentSlide(currentSlide === slideLength -1 ? 0 : currentSlide + 1)
	}

  return (
    <div className='slider'>
        {slides.map((slide, index)=>(
            <div key={index} className={index === currentSlide ? 'slider__item current' : 'slider__item'} id={index === currentSlide -1 ? 'close' : ''}>
                <h2>{slide.title}</h2>
                <p>{slide.text}</p>
                <img src={`../../../assets/${slide.image}`} alt=""/>
            </div>
        ))}
    </div>
  )
}

export default Carousel