import React from 'react';


const Input = ({id, label, type= "text", register, required, errors, disabled}) => {
  
  return (
   
    <div className='form'>
       
        <input
             id={id} 
             type={type}
             placeholder=' '
             disabled={disabled}
             {...register(id, { required })} 
            className={`form__input ${errors[id] ? 'form__input__error' : ''}`}
         />
        <label className={`form__label ${errors[id] ? 'form__input__error' : ''}`}>            
            {label}
        </label>
        <br />  {errors[id] && <span>{errors[id].message}</span>} 
    </div>
  )
}

export default Input
