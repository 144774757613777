import React from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import { ParallaxBanner } from 'react-scroll-parallax';

const Hero = ({data, title}) => {
  
  return (     
    <div className="hero hero_bglayer">  
        <ParallaxBanner
          layers={[{ image: data, speed: -15 }]}
          style={{ aspectRatio: '4 / 1' }}
        >
          <div className="hero__parallax">
            <h1>{title}</h1>
        </div>
        </ParallaxBanner>   
      </div>  
  )
}

export default Hero
