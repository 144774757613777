import React from 'react';
import { Toaster } from "react-hot-toast";

const ToasterHook = () => {
  return (
   <Toaster />
  )
}

export default ToasterHook
