import React from 'react';

import Container from '../../../../common/Container'
import RequestForm from './RequestForm';


const Intro = ({data}) => {
    const {heading, paragraphs} = data;
  return (
    <section className='intro'>
        <Container>
                <div className="flex">
                    <div className="col-8">
                        <h3 className='heading'>{heading}</h3>
                        <p className='paragraph'>{paragraphs}</p>
                    </div>
                    <div className="col-4">
                        <RequestForm />
                    </div>
                </div>
        </Container>
    </section>
  )
}

export default Intro
