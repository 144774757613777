import React from 'react';
import Button from '../../common/Button';

const LeftSide = ({details}) => {
  const {heading, description} = details;
  
  return (
    <div className='about__left'>
        <h3 className="heading">{heading}</h3>
        <div className="mb-2">
        { description.length > 0 ? 
              description.map((paragraph, index) => <p className='paragraph' key={index}>{paragraph} </p>)
            : ''}
        </div>
        <Button small={true} outline={true} label='Contact Us' />
    </div>
  )
}

export default LeftSide
