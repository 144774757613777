import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useLocation } from 'react-router-dom';


import { useBoundStore } from '../../../store/index';
import Container from '../../common/Container';
import Hero from '../../common/Hero';
import RequestForm from './RequestForm';
import List from '../../common/List';


const ServicePage = () => {
  const location = useLocation();

  useEffect(()=>{
    window.scrollTo(0, 0)
      },[location]);

  const params = useParams();
  const title =  params.id.replace('_' , ' ').toUpperCase();
 
  const data = useBoundStore((state) => state.servicesPage)
 
  return (
    <>
      <Helmet>
				<title>Services - {title}</title>
				<meta name='description' content='travel friends contact page' />
			</Helmet>
      <section className='service'>
            <Hero data={data[params.id].image} title={data[params.id].heading}/>
            <Container>
                <div className="flex about__intro">
                  <div className="col-8">
                      <h3 className="heading">{data[params.id].heading}</h3>
                      {data[params.id].description.length > 0 && 
                          data[params.id].description.map((paragraph, index)=>
                            <p key={index} className='paragraph'>{paragraph}</p>
                          )
                      }
                      <div>
                          <p>We Offer:</p>
                          <div>
                              {data[params.id].list.length > 0 &&
                                data[params.id].list.map((paragraph, index)=>
                                  <List key={index} icon='BiCheck' details={paragraph} />
                                )
                              }
                          </div>
                      </div>
                  </div>
                  <div className="col-4">
                        <RequestForm />
                  </div>
                </div>
            </Container>
      </section>
    </>
  )
}

export default ServicePage
