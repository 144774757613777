import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet-async';

import {useBoundStore } from '../../../store/index'
import Container from '../../common/Container';
import Hero from '../../common/Hero';
import ContactForm from './ContactForm';
import ContactLink from '../../common/ContactLink';

const ContactPage = () => {

  const {contactPage: {image, heading, subheading, paragraph}, contactLinks} = useBoundStore((state) => state);

  useEffect(()=>{
    window.scrollTo(0, 0)
      },[]);      

  return (
    <>
    		<Helmet>
				<title>Contact page</title>
				<meta name='description' content='travel friends contact page' />
			</Helmet>
      <section className='contact'>      
          <Hero data={image} title='Contact Us' />          
          <Container>
              
                <div className="col-12 contact__header">
                    <h1 className='heading-secondary'>{heading}</h1>
                    <p className='paragraph'>
                      {subheading}
                    </p>
                </div>
             
              <div className="flex">
                  <div className="col-6">
                     <p className='paragraph'>{paragraph}</p>
                     <div className="contact__links">
                      {contactLinks.length > 0 && contactLinks.map((item, index) => (
                          <ContactLink key={index} data={item}/>
                      ))}
                        
                     </div>
                  </div>
                  <div className="col-6">
                      <ContactForm />
                  </div>
              </div>
          </Container>
      </section>
    </>
  )
}

export default ContactPage