import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet-async';

import AOS from 'aos';
import 'aos/dist/aos.css';

import { useBoundStore } from '../../../store/index';

import Carousel from '../../common/carousel/Carousel';
import Intro from './parts/intro/Intro';
import Branding from './parts/branding/Branding';
import Services from './parts/services/Services';
import Gallery from './parts/gallery/Gallery';
import Reviews from './parts/reviews/Reviews';


const HomePage = () => {

  const {slides, intro, branding, cards, gallery, reviews} = useBoundStore((state) => state) ;

  useEffect(()=>{
    window.scrollTo(0, 0)
      },[]);

  useEffect(()=>{
    AOS.init({duration:1000})
  },[]);


  return (
    <>
       <Helmet>
				<title>Travel Friends</title>
				<meta	name='description'	content='Travel to the world with travel frirends'	/>
				<meta name='keywords' content='travel, travel tours, airline' />
			</Helmet>

      <Carousel slides={slides} />
      <Intro  data={intro}/>
      <Services data={cards} />
      <Branding data={branding}/>      
      <Gallery data={gallery} />
      <Reviews data={reviews} />
    </>
  )
}

export default HomePage