import React from 'react';
import {FaRegMap, FaRegEnvelope, FaPhoneAlt, FaFacebookSquare, FaGooglePlusSquare, FaTwitterSquare, FaInstagramSquare, FaRssSquare} from "react-icons/fa";

import Container from '../Container';
import { useBoundStore } from '../../../store/index';
import SubMenuLink from '../SubMenuLink';

const Footer = () => {

  const {links} = useBoundStore((state) => state) 

  return (
    <footer className='footer'>
        <Container>
            <div className="footer__main wrap">
                <div className="col-6 footer__items">
                  <h3>Go Electrical US </h3> 
                  <span>Your Neighborhood Electrician</span>               
                  <p>Our company values are derived from years of experience in the home health industry and through identification of areas that will improve our clients’ well being.</p>
                </div>
                <div className="col-3 footer__links">
                  <h3>Navigaiton</h3>
                  <ul className='pt-1'>
                      <li><a href="#">Home</a></li>
                      <li><a href="#about">About US</a></li>
                      <li><a href="#services">Services</a></li>
                      <li><a href="#contact">Contact Us</a></li>
                  </ul>
               </div>
               <div className="col-3 flex footer__services">
                  <h3>Services</h3>
                  <ul className='pt-1'>
                    {links && links.map((link, index) => <li key={index}><SubMenuLink data={link}/></li>)}                 
                  </ul>
               </div>
            </div>
          </Container>
            <div className="footer__second">
                <Container>
                <div className="contact">
                        <div className="item flex">
                            <FaRegMap size={40} className='icon' />
                            <p className='ml-1'>2625 Central St., Evanston, IL, 60201</p>
                        </div>
                        <div className="item flex">
                          <FaRegEnvelope size={40} className='icon' />
                          <p className='ml-1'>info@hcnpprofessionals.com</p>
                        </div>
                        <div className="item flex">
                          <FaPhoneAlt size={30} className='icon' />
                          <p className='ml-1'>847-881-5548</p>
                        </div>
                    </div>
                </Container>
            </div>        
            <div className="col-12 footer__bottom">
               <p>Copyright Go Electrical US LLC</p>
            </div>
        
    </footer>
  )
}

export default Footer