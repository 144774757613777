import React, {useState, useEffect} from 'react';
import {useForm, FieldValues, SubmitHandler} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from "react-hot-toast";
import {FaHospitalAlt} from 'react-icons/fa';

import Input from '../../../../common/Input';
import TextArea from '../../../../common/TextArea';
import Button from '../../../../common/Button';
import { ContactSchema } from '../../../../../utils/YupSchemas';


const RequestForm = () => {
  
    const [isLoading, setIsLoading] = useState(false)

    const { register, handleSubmit, reset, formState, formState: {isSubmitSuccessful, errors} } = useForm({ resolver: yupResolver(ContactSchema)});

    const onSubmit = (data) =>{
            
         toast.error('SALUT')
         setIsLoading(false)
         
    }

    useEffect(() => {
      if (formState.isSubmitSuccessful) {
        console.log('merge')
        reset({name: '', email: '', phone: '', message: ''});
      }
    }, [formState, reset]);
    
  return (
    <div className='quote'>         
          <div className='quote__header'> 
          <div className="icon">
            <FaHospitalAlt size={30} className='icon'/> 
          </div>
          <div className="devider"></div> 
            <div className="description">         
              <p>Don't Hesitate To Ask</p>      
              <h4>Request a Quote</h4> 
            </div>                        
        </div>
         
            <div className="quote__body">           
                <Input id="name"
                      label="Name"
                      disabled={isLoading}
                      register={register}
                      errors={errors}
                      required                     
                 />
               
                 <Input id="email"
                      label="Email"
                      disabled={isLoading}
                      register={register}
                      errors={errors}
                      required
                 />
                 
                   <Input id="phone"
                      label="Phone No"
                      disabled={isLoading}
                      register={register}
                      errors={errors}
                      required
                 />
                 <TextArea id="message" 
                    label="Message"
                    disabled={isLoading}
                    register={register}
                    errors={errors}
                    required                    
                 />

                <Button onClick={handleSubmit(onSubmit)} label='Send Quote'/>  
                
            </div>                         
         
    </div>
  )
}

export default RequestForm
