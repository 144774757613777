import React from 'react';

import ColapsedList from '../../common/ColapsedList';
import List from '../../common/List';

const WhyChooseUs = ({list, data}) => {  
  const {heading, paragraph, details} = data;

  return (
    <div className="flex colapsed__container" data-aos="fade-right">
        <div className="col-5">
            <ColapsedList list={list} />
        </div>
        <div className="col-7 pl-3">
            <h3 className='heading'>{heading}</h3>
            <p className="paragraph">{paragraph}</p>
        
            <div className='mt-2'>
              {details.length > 0 && details.map((detail, index) => <List key={index} icon='BiCheck' details={detail} />)}              
            </div>
        </div>
    </div>    
  )
}

export default WhyChooseUs
