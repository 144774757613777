import React, {useState, useEffect, useRef} from 'react';

import ReviewsArrows from './ReviewsArrows';
import ReviewsControl from './ReviewsControl';
import ReviewCard from './ReviewCard';
import Container from '../../../../common/Container';
 
const Reviews = ({data}) => {
  
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideInterval = useRef();

  useEffect(()=>{
    startSlideTimer()
    return () => stopSlideTimer()
  }, []);

  const startSlideTimer = () =>{
    stopSlideTimer()
    slideInterval.current = setInterval(() => {
        setCurrentSlide((currentSlide) => currentSlide < data.length -1 ? currentSlide +1 : 0)
    }, 7000)
  };

  const stopSlideTimer = () =>{
    if(slideInterval.current){
        clearInterval(slideInterval.current)
    }
  }

  const prev = ()=>{
       
    startSlideTimer()
    const index =  currentSlide > 0 ? currentSlide -1 : data.length - 1        
    setCurrentSlide(index)
};

const next = () =>{
    
    startSlideTimer()
    const index = currentSlide < data.length - 1 ? currentSlide + 1 : 0       
    
    setCurrentSlide(index)
};

const switchIndex = (index) =>{
    startSlideTimer()
    setCurrentSlide(index)
}

  return (
    <section className="reviews" data-aos="fade-right" >
      <Container>
        <div className="wrapper">
          <div className="inner">
            {data && data.map((review, index) => (
              <ReviewCard review={review} key={index} index={index} currentIndex={currentSlide}/>
            ))}
          </div>
          <ReviewsControl slides={data} currentIndex={currentSlide} switchIndex={switchIndex}/>
          <ReviewsArrows prev={prev} next={next} />
        </div>
      </Container>
    </section>
  )
}

export default Reviews
