import React from 'react';
import loadable from "@loadable/component"
import { IconBaseProps, IconType } from "react-icons/lib"

const Icon = ({iconName, propsIcon}) => {
    const lib = iconName.replace(/([a-z0-9])([A-Z])/g, '$1 $2').split(" ")[0].toLocaleLowerCase();
    const ElementIcon = loadable(() => import(`react-icons/${lib}/index.js`), {
        resolveComponent: (el) => el[iconName]})
 
    return <ElementIcon {...propsIcon} />
}

export default Icon