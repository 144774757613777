import React from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';

const ParallaxHero = ({image, heading, paragraph}) => {
  return (
    <ParallaxBanner
        layers={[{ image: image, speed: -15 }]}
        style={{ aspectRatio: '5 / 1' }}
      >
        <div className="parallax">
           <h1>{heading}</h1>
           <p className='paragraph'>{paragraph}</p>
       </div>
      </ParallaxBanner>    
  )
}

export default ParallaxHero
