import React from 'react'

const TextArea = ({id, register, required, errors, disabled, label }) => {
  return (
    <div className="textarea">
        <textarea
            id={id}           
            placeholder=' '
            disabled={disabled}
            {...register(id, {required})}
            className={`form__input ${errors[id] ? 'form__input__error' : ''}`}
        ></textarea>
        <label className={`form__label ${errors[id] ? 'form__input__error' : ''}`}>            
            {label}
        </label>
        <br />  {errors[id] && <span>{errors[id].message}</span>} 
    </div>
  )
}

export default TextArea
