import React from 'react';
import Icon from './Icon';

const ContactLink = ({data}) => {

  const {icon, paragraph} = data;

  return (
    <div className='contact__link'>
      <Icon iconName={icon} propsIcon={{ size: 30, fill: '' }} />  
      <p>{paragraph}</p>
    </div>
  )
}

export default ContactLink
