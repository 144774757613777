import React from 'react';
import { NavLink } from 'react-router-dom';

const SubMenuLink = ({data}) => {
 const {url, label} = data;
  return (
    <NavLink to={url}>{label}</NavLink> 
  )
}

export default SubMenuLink
