import React, {useState, useEffect} from 'react';
import {useForm, FieldValues, SubmitHandler} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from "react-hot-toast";

import Input from '../../common/Input';
import Button from '../../common/Button';
import TextArea from '../../common/TextArea';
import { ContactSchema } from '../../../utils/YupSchemas';

const RequestForm = () => {

    const [isLoading, setIsLoading] = useState(false);
    const { register, handleSubmit, reset, formState, formState: {isSubmitSuccessful, errors} } = useForm({ resolver: yupResolver(ContactSchema)});

    const onSubmit = (data) =>{            
        toast.error('SALUT')
        setIsLoading(false)
        
   }

   useEffect(() => {
    if (formState.isSubmitSuccessful) {
      console.log('merge')
      reset({name: '', email: '', phone: '', message: ''});
    }
  }, [formState, reset]);

  return (
    <div className="contact__form">
        <div className="contact__form__body">
        <Input id="name"
                      label="Name"
                      disabled={isLoading}
                      register={register}
                      errors={errors}
                      required                     
                 />
               
                 <Input id="email"
                      label="Email"
                      disabled={isLoading}
                      register={register}
                      errors={errors}
                      required
                 />
                 
                   <Input id="phone"
                      label="Phone No"
                      disabled={isLoading}
                      register={register}
                      errors={errors}
                      required
                 />
                 <TextArea id="message" 
                    label="Message"
                    disabled={isLoading}
                    register={register}
                    errors={errors}
                    required                    
                 />

                <Button onClick={handleSubmit(onSubmit)} label='Send Quote'/>  
        </div>
    </div>
  )
}

export default RequestForm
