
export const SubMenuLinksStore = (set) => ({ 
  links: [
    {url: '/services/electrical_wiring', label: 'Electrical Wiring'}, 
    {url: '/services/electrical_panel', label: 'Electrical Panel Upgrates'},
    {url: '/services/indoor_outdoor_lighting', label:'Indoor/Outdoor Lighting'},
    {url: '/services/smoke_alarms', label: 'Smoke Alarms'},
    {url: '/services/electric_vehicle_chargers', label: 'EV Charging Stations'},
    {url: '/services/generators', label: 'Generators' }
  ]
})


