import React from 'react';
import Container from '../../../../common/Container';

const Gallery = ({data}) => {
    
  return (
    <section className='gallery' data-aos="fade-right">    
    <Container>
        <div className="gallery__grid">
            {data.length > 0 ? 
                data.map((image, index) => 
                <figure className="gallery__item" key={index}>
                    <img key={index} src={`../../../assets/${image}`} alt="" />
                </figure>
                ) : ''
            }
        </div>
    </Container>
    </section>
  )
}

export default Gallery