import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

import { CarouselStore } from './CarouselStore';
import { HomePageStore } from './HomePageStore';
import { SubMenuLinksStore } from './SubMenuLinksStore';
import {ContactPageStore} from './ContactPageStore';
import {AboutPageStore} from './AboutPageStore';
import { ContactLinksStore } from './ContactLinksStore';
import { ServicesStore } from './ServicesStore';


export const useBoundStore =  create(devtools((...state) =>({
    ...CarouselStore(...state),
    ...HomePageStore(...state),
    ...SubMenuLinksStore(...state),
    ...ContactPageStore(...state),
    ...AboutPageStore(...state),
    ...ContactLinksStore(...state),
    ...ServicesStore(...state),
})))