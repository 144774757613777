import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet-async';

import AOS from 'aos';
import 'aos/dist/aos.css';

import {useBoundStore } from '../../../store/index'
import Container from '../../common/Container';
import Hero from '../../common/Hero';
import LeftSide from './LeftSide';
import Card from '../../common/Card';
import ParallaxHero  from '../../common/ParallaxHero';
import WhyChooseUs from './WhyChooseUs';

const AboutPage = () => {

  const {image, intro, details, colapsedList, cards, parallax, details_second} = useBoundStore((state) => state.AboutPage);

  useEffect(()=>{
    window.scrollTo(0, 0)
      },[]);

      useEffect(()=>{
        AOS.init({
          offset:1,         
          duration:1000
        })
      },[]);


  return (
    <>
      <Helmet>
				<title>About Us</title>
				<meta name='description' content='travel friends contact page' />
			</Helmet>

      <section className='about'>
        <Hero data={image} title='About Us' /> 
        <Container>
            <div className="col-12 about__header">
                <h1 className='heading-secondary'>{intro.heading}</h1>
                <p className="paragraph">{intro.subheading}</p>
            </div>
            <div className="flex about__intro" data-aos="fade-right">
              <div className="col-6">
                  <LeftSide details={details}/>
              </div>
              <div className="col-6 flex">
                  { cards.length > 0 ? cards.map((card, index) =>
                    <div className="col-6"  data-aos="fade-right">
                      <Card key= {index} icon={card.icon} heading={card.heading} paragraphs={card.paragraphs}/>
                      </div>
                  ) : ''}
              </div>
            </div>
        </Container>
        <div className='parallax-hero bg-overlay'>
            <ParallaxHero image={parallax.image} heading={parallax.heading} paragraph={parallax.paragraph}/>
        </div>
        <Container>
            <div className="colapsed">   
              <WhyChooseUs list={colapsedList} data={details_second}/>           
            </div>            
        </Container>
      </section>
    </>
  )
}

export default AboutPage