import React from 'react';
import Icon from './Icon';

const Card = ({icon, heading, paragraphs}) => {
  return (
    <div className='card'>
        <div className="box">
            <div className="inner">
                <div className="icon">                   
                <Icon iconName={icon} propsIcon={{ size: 50 }} />                
                </div>
                <h3>{heading}</h3>
                <p>{paragraphs}</p>
            </div>
        </div> 
    </div>
  )
}

export default Card
