import React from 'react';
import { IconType } from "react-icons/lib";

const Button = ({label, disabled, onClick, small, outline, icon: Icon}) => {
 
  return (
    <div className="">
        <button onClick={onClick} disabled={disabled} 
            className={`button ${small && 'small'} ${outline && 'outline'}`}>
            {Icon && (
                    <Icon
                    size={24}
                    className="absolute left-4 top-3"/>
                )}
            {label}
        </button>
    </div>
  )
}

export default Button
